<template lang="pug">
	main#cases
		Header
		Banner
		Portfolio
		Formulario(formTitle="Está na hora do seu negócio ir além!" subTitle="Está na hora do seu negócio ir além!")

</template>

<script>
import Banner from '@sections/Cases/Banner/Banner'
import Portfolio from '@sections/Cases/Portfolio/Portfolio'
import Formulario from '@components/Formulario/Formulario'
import Header from '@components/Header/Header'

export default {
	name: "view-cases",
	components: {
		Header,
		Portfolio,
        Formulario,
		Banner,
	},
	metaInfo() {
		return {
			title: 'Cases',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
}
</script>

<style lang="stylus" scoped src="./Cases.styl"></style>
