<template lang="pug">
    section#portfolio
        ul
            li(v-for="item, index in portfolio", ref="li")
                router-link(:to="{name: 'Case', params: {slug: item.slug}}")
                    img(:src="item.image")
                    .texto
                        h3 {{item.nome}}
                        p {{item.description}}
</template>

<script>
export default {
    name: "section-portfolio",
    data() {
        return {
            scenes: [],
            portfolio: [
                {
                    nome: 'Almha',
                    description: 'Business Design',
                    image: require('@images/cases/almha.jpg'),
                    slug: 'almha',
                },
                {
                    nome: 'Faculdade Inspirar',
                    description: 'Identidade institucional',
                    image: require('@images/cases/inspirar.jpg'),
                    slug: 'faculdade-inspirar',
                },
            ]
        }
    },
    mounted() {
		this.initScenes()
		setTimeout(() => {
            this.$scrollmagic.addScene(this.scenes)
        }, 1000)
    },
    methods: {
        initScenes() {
            this.scenesEquipe()
        },
        scenesEquipe() {
			// Boxes
			this.$refs.li.forEach((item, index) => {
				this.scenes.push(this.$scrollmagic.scene({
					triggerElement: '#portfolio',
					triggerHook: 0.5,
					reverse: false
				}).setTween(`#portfolio li:nth-child(${index+1})`, {
					css: {
						animation: 'boxAnimation 1s linear forwards ' + (0.5*index+'s'),
					}
				}))
			});
		},
    }
}
</script>

<style lang="stylus" scoped src="./Portfolio.styl"></style>
<style lang="stylus">
    @keyframes boxAnimation
        100%
            opacity 1; transform translateY(0)
</style>