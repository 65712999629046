<template lang="pug">
    section#banner
        SvgIcon(data="@svgs/servicos/servicos-quemsomos.svg", original).web
        .wrapper
            .container
                .img
                    img(:src="require('@images/cases/cases_01.jpg')")
                .text
                    p
                        |Exploramos dados,
                        br
                        |comportamentos e processos.
                        br
                        |E as oportunidades aparecem.
                    h2
                        | V
                        span._1100 e
                        | ja
                        |  qu
                        span._1100 e
                        | m f
                        span._1100 a
                        | z
                        span.br
                            br
                        | part
                        span._1100 e
                        |  da
                        |  n
                        span._1100 o
                        | ssa
                        span.br
                            br
                        |  r
                        span._1100 e
                        | d
                        span._1100 e
                        |  n
                        span._1100 eu
                        | ral
                SvgIcon(data="@svgs/m.svg", original).m
                .img
                    img(:src="require('@images/cases/cases_02.jpg')")
            ul.icons
                li
                    SvgIcon(data="@svgs/icon_01.svg", original)._svg01
                li
                    SvgIcon(data="@svgs/icon_02.svg", original)._svg02
                li
                    SvgIcon(data="@svgs/icon_03.svg", original)._svg03
</template>

<script>
export default {
    name: "section-banner",
}
</script>

<style lang="stylus" scoped src="./Banner.styl"></style>
